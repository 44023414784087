/**
 * Post
 *
 * 01. Responsive Table
 * 02. Sticky Social Bar
 * 03. Sticky Elements
 * 04. Comments Max Length
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

    /**
     * Responsive Table
     */
    $('.single-post__body table').each(function () {
        var table = $(this),
            th = table.find('thead th'),
            headers = [];

        th.each(function () {
            headers.push($(this).text());
        });

        table.find('tbody tr').each(function () {
            var cells = $(this).find('td');

            headers.map(function (value, index) {
                cells.eq(index).attr('data-label', value);
            });
        });
    });


    /**
     * Sticky Social Bar
     */
    var shareSticky = $(".js-share-sticky");

    $(window).scroll(function() {
        var topPos = $(this).scrollTop();

        shareSticky.toggleClass('is-show', topPos >= 200);
    });


    /**
     * Sticky Elements
     *
     * @requires plugins/jquery.sticky-kit.js
     */
    (function () {
        var sticky = $('.js-sticky_column');

        /**
         * JavaScript Media Queries
         *
         * @see https://www.sitepoint.com/javascript-media-queries/
         */
        var mq = window.matchMedia("(min-width: 900px)");
        mq.addEventListener('change', widthChange);
        widthChange(mq);

        // media query change
        function widthChange(event) {
            if (event.matches) {
                // desktop
                sticky.stick_in_parent({
                    parent: '.js-sticky_parent',
                    offset_top: 60
                });
            } else {
                // mobile
                sticky.trigger('sticky_kit:detach');
            }
        }
    }());


    /**
     * Comments Max Length
     *
     * @link http://wpengineer.com/2321/comment-length-limiter/
     */
    var comment_input = $('#js-comment'),
        comment_limit = $('#js-comment-limit'),
        comment_submit = $('#js-comment-submit'),
        comment_limit_chars = 700;

    // display how many characters are left
    comment_limit.text(comment_limit_chars);
    comment_submit.hide();

    comment_input.bind( 'keyup', function() {
        // calculate characters left
        var comment_length = $(this).val().length;
        var chars_left = comment_limit_chars - comment_length;

        // display characters left
        comment_limit.text( chars_left );

        // hide submit button if too many chars were used
        if (chars_left > 0) {
            comment_submit.show();
        }
    });


    /**
     * Accordion
     *
     * @requires plugins/jquery.accordion.js
     */
    (function () {
        $('.js-accordion').accordion({
            panelSelector: '.c-accordion__item',
            titleSelector: '.c-accordion__header',
            contentSelector: '.c-accordion__content',
            activeClass: 'is-open'
        });
    }());
}(jQuery));