/**
 * Mailchimp
 *
 * @link https://css-tricks.com/form-validation-part-4-validating-mailchimp-subscribe-form/
 * @link https://codepen.io/cferdinandi/pen/ZyLzQV
 *
 * @global jQuery
 * @author Matteo Casonato
 */
(function (w, d) {
    'use strict';

    var mailchimp = d.querySelector('.js-mailchimp');

    if (!mailchimp) {
        return;
    }

    // A Validity State Polyfill
    // Make sure that ValidityState is supported in full (all features)
    var supported = function () {
        var input = d.createElement('input');
        return ('validity' in input && 'badInput' in input.validity && 'patternMismatch' in input.validity && 'rangeOverflow' in input.validity && 'rangeUnderflow' in input.validity && 'stepMismatch' in input.validity && 'tooLong' in input.validity && 'tooShort' in input.validity && 'typeMismatch' in input.validity && 'valid' in input.validity && 'valueMissing' in input.validity);
    };

    /**
     * Generate the field validity object
     * @param  {Node]} field The field to validate
     * @return {Object}      The validity object
     */
    var getValidityState = function (field) {

        // Variables
        var type = field.getAttribute('type') || input.nodeName.toLowerCase();
        var isNum = type === 'number' || type === 'range';
        var length = field.value.length;
        var valid = true;

        // If radio group, get selected field
        if (field.type === 'radio' && field.name) {
            var group = d.getElementsByName(field.name);
            if (group.length > 0) {
                for (var i = 0; i < group.length; i++) {
                    if (group[i].form === field.form && field.checked) {
                        field = group[i];
                        break;
                    }
                }
            }
        }

        // Run validity checks
        var checkValidity = {
            badInput: (isNum && length > 0 && !/[-+]?[0-9]/.test(field.value)), // value of a number field is not a number
            patternMismatch: (field.hasAttribute('pattern') && length > 0 && new RegExp(field.getAttribute('pattern')).test(field.value) === false), // value does not conform to the pattern
            rangeOverflow: (field.hasAttribute('max') && isNum && field.value > 0 && Number(field.value) > Number(field.getAttribute('max'))), // value of a number field is higher than the max attribute
            rangeUnderflow: (field.hasAttribute('min') && isNum && field.value > 0 && Number(field.value) < Number(field.getAttribute('min'))), // value of a number field is lower than the min attribute
            stepMismatch: (isNum && ((field.hasAttribute('step') && field.getAttribute('step') !== 'any' && Number(field.value) % Number(field.getAttribute('step')) !== 0) || (!field.hasAttribute('step') && Number(field.value) % 1 !== 0))), // value of a number field does not conform to the stepattribute
            tooLong: (field.hasAttribute('maxLength') && field.getAttribute('maxLength') > 0 && length > parseInt(field.getAttribute('maxLength'), 10)), // the user has edited a too-long value in a field with maxlength
            tooShort: (field.hasAttribute('minLength') && field.getAttribute('minLength') > 0 && length > 0 && length < parseInt(field.getAttribute('minLength'), 10)), // the user has edited a too-short value in a field with minlength
            typeMismatch: (length > 0 && ((type === 'email' && !/^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(field.value)) || (type === 'url' && !/^(?:(?:https?|HTTPS?|ftp|FTP):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*)(?::\d{2,5})?(?:[\/?#]\S*)?$/.test(field.value)))), // value of a email or URL field is not an email address or URL
            valueMissing: (field.hasAttribute('required') && (((type === 'checkbox' || type === 'radio') && !field.checked) || (type === 'select' && field.options[field.selectedIndex].value < 1) || (type !== 'checkbox' && type !== 'radio' && type !== 'select' && length < 1))) // required field without a value
        };

        // Check if any errors
        for (var key in checkValidity) {
            if (checkValidity.hasOwnProperty(key)) {
                // If there's an error, change valid value
                if (checkValidity[key]) {
                    valid = false;
                    break;
                }
            }
        }

        // Add valid property to validity object
        checkValidity.valid = valid;

        // Return object
        return checkValidity;
    };

    // If the full set of ValidityState features aren't supported, polyfill
    if (!supported()) {
        Object.defineProperty(HTMLInputElement.prototype, 'validity', {
            get: function ValidityState() {
                return getValidityState(this);
            },
            configurable: true
        });
    }

    // Validate the field
    var hasError = function (field) {
        // Don't validate submits, buttons, file and reset inputs, and disabled fields
        if (field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') {
            return;
        }

        // Get validity
        var validity = field.validity;

        // If valid, return null
        if (validity.valid) {
            return;
        }

        // If field is required and empty
        if (validity.valueMissing) {
            return 'Perfavore compila questo campo.';
        }

        // If not the right type
        if (validity.typeMismatch) {
            // Email
            if (field.type === 'email') {
                return 'Si prega di inserire un indirizzo email.';
            }
            // URL
            if (field.type === 'url') {
                return 'Si prega di inserire un URL.';
            }
        }

        // If too short
        if (validity.tooShort) {
            return 'Si prega di allungare questo testo a ' + field.getAttribute('minLength') + ' caratteri o più. Attualmente stai utilizzando ' + field.value.length + ' caratteri.';
        }

        // If too long
        if (validity.tooLong) {
            return 'Si prega di accorciare questo testo a ' + field.getAttribute('maxLength') + ' caratteri. Attualmente stai utilizzando ' + field.value.length + ' caratteri.';
        }

        // If pattern doesn't match
        if (validity.patternMismatch) {
            // If pattern info is included, return custom error
            if (field.hasAttribute('title')) {
                return field.getAttribute('title');
            }
            // Otherwise, generic error
            return 'Si prega di abbinare il formato richiesto.';
        }

        // If number input isn't a number
        if (validity.badInput) {
            return 'Per favore inserisci un numero.';
        }

        // If a number value doesn't match the step interval
        if (validity.stepMismatch) {
            return 'Si prega di selezionare un valore valido.';
        }

        // If a number field is over the max
        if (validity.rangeOverflow) {
            return 'Si prega di inserire un valore inferiore a ' + field.getAttribute('max') + '.';
        }

        // If a number field is below the min
        if (validity.rangeUnderflow) {
            return 'Si prega di inserire un valore superiore a ' + field.getAttribute('min') + '.';
        }

        // If all else fails, return a generic catchall error
        return 'Il valore inserito per questo campo non è valido.';
    };

    // Show an error message
    var showError = function (field, error) {

        // Add error class to field
        Util.addClass(field, 'error');

        // If the field is a radio button and part of a group, error all and get the last item in the group
        if (field.type === 'radio' && field.name) {
            var group = field.form.querySelectorAll('[name="' + field.name + '"]');
            if (group.length > 0) {
                for (var i = 0; i < group.length; i++) {
                    Util.addClass(group[i], 'error');
                }
                field = group[group.length - 1];
            }
        }

        // Get field id or name
        var id = field.id || field.name;
        if (!id) {
            return;
        }

        // Check if error message field already exists
        // If not, create one
        var message = field.form.querySelector('.error-message#error-for-' + id);
        if (!message) {
            message = d.createElement('div');
            message.className = 'error-message';
            message.id = 'error-for-' + id;

            // If the field is a radio button or checkbox, insert error after the label
            var label;
            if (field.type === 'radio' || field.type === 'checkbox') {
                label = field.form.querySelector('label[for="' + id + '"]') || field.parentNode;
                if (label) {
                    label.parentNode.insertBefore(message, label.nextSibling);
                }
            }

            // Otherwise, insert it after the field
            if (!label) {
                field.parentNode.insertBefore(message, field.nextSibling);
            }

        }

        // Add ARIA role to the field
        field.setAttribute('aria-describedby', 'error-for-' + id);

        // Update error message
        message.innerHTML = error;

        // Show error message
        message.style.display = 'block';
        message.style.visibility = 'visible';

    };

    // Remove the error message
    var removeError = function (field) {

        // Remove error class to field
        Util.removeClass(field, 'error');

        // Remove ARIA role from the field
        field.removeAttribute('aria-describedby');

        // If the field is a radio button and part of a group, remove error from all and get the last item in the group
        if (field.type === 'radio' && field.name) {
            var group = field.form.querySelectorAll('[name="' + field.name + '"]');
            if (group.length > 0) {
                for (var i = 0; i < group.length; i++) {
                    Util.removeClass(group[i], 'error');
                }
                field = group[group.length - 1];
            }
        }

        // Get field id or name
        var id = field.id || field.name;
        if (!id) {
            return;
        }

        // Check if an error message is in the DOM
        var message = field.form.querySelector('.error-message#error-for-' + id + '');
        if (!message) {
            return;
        }

        // If so, hide it
        message.innerHTML = '';
        message.style.display = 'none';
        message.style.visibility = 'hidden';

    };

    // Serialize the form data into a query string
    // Forked and modified from https://stackoverflow.com/a/30153391/1293256
    var serialize = function (form) {

        // Setup our serialized data
        var serialized = '';

        // Loop through each field in the form
        for (i = 0; i < form.elements.length; i++) {
            var field = form.elements[i];

            // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
            if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

            // Convert field data to a query string
            if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                serialized += '&' + encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value);
            }
        }

        return serialized;

    };

    // Display the form status
    w.displayMailChimpStatus = function (data) {

        // Make sure the data is in the right format and that there's a status container
        if (!data.result || !data.msg || !mcStatus) {
            return;
        }

        // Update our status message
        mcStatus.innerHTML = data.msg;

        // If error, add error class
        if (data.result === 'error') {
            Util.removeClass(mcStatus, 'success-message');
            Util.addClass(mcStatus, 'error-message');
            return;
        }

        // Otherwise, add success class
        Util.removeClass(mcStatus, 'error-message');
        Util.addClass(mcStatus, 'success-message');
    };

    // Submit the form
    var submitMailChimpForm = function (form) {

        // Get the Submit URL
        var url = form.getAttribute('action');
        url = url.replace('/post?u=', '/post-json?u=');
        url += serialize(form) + '&c=displayMailChimpStatus';

        // Create script with url and callback (if specified)
        var ref = d.getElementsByTagName('script')[0];
        var script = d.createElement('script');
        script.src = url;

        // Create a global variable for the status container
        w.mcStatus = form.querySelector('.mc-status');

        // Insert script tag into the DOM (append to <head>)
        ref.parentNode.insertBefore(script, ref);

        // After the script is loaded (and executed), remove it
        script.onload = function () {
            this.remove();
        };

    };


    var gdprFieldIsValid = function (form) {
        var gdprCheckbox = form.querySelectorAll('.gdpr');
        var gdprError = true;

        for (var j = 0; j < gdprCheckbox.length; j++) {
            if (gdprCheckbox[j].checked) {
                gdprError = false;
            }
        }

        if (gdprError) {
            showError(gdprCheckbox[gdprCheckbox.length - 1], 'Seleziona almeno un’opzione');
        } else {
            removeError(gdprCheckbox[gdprCheckbox.length - 1]);
        }

        return !gdprError;
    };

    // Add the novalidate attribute when the JS loads
    var forms = d.querySelectorAll('.validate');
    for (var i = 0; i < forms.length; i++) {
        var form = forms[i];

        form.setAttribute('novalidate', true);

        // Listen to all blur events
        form.addEventListener('blur', function (event) {

            // Only run if the field is in a form to be validated
            if (!Util.hasClass(event.target.form, 'validate')) {
                return;
            }

            // Validate the field
            var error = hasError(event.target);

            // If there's an error, show it
            if (error) {
                showError(event.target, error);
                return;
            }

            // Otherwise, remove any existing error message
            removeError(event.target);

        }, true);

        form.addEventListener('change', function (event) {
            if (Util.hasClass(event.target, 'gdpr')) {
                gdprFieldIsValid(event.target.form);
            }
        });


        // Check all fields on submit
        form.addEventListener('submit', function (event) {

            // Only run on forms flagged for validation
            if (!Util.hasClass(event.target, 'validate')) {
                return;
            }

            // Prevent form from submitting
            event.preventDefault();

            // Get all of the form elements
            var fields = event.target.elements;

            // Validate each field
            // Store the first field with an error to a variable so we can bring it into focus later
            var error, hasErrors;
            for (var i = 0; i < fields.length; i++) {
                error = hasError(fields[i]);
                if (error) {
                    showError(fields[i], error);
                    if (!hasErrors) {
                        hasErrors = fields[i];
                    }
                }
            }

            // If there are errrors, don't submit form and focus on first element with error
            if (hasErrors) {
                hasErrors.focus();
            }

            var gdprIsValid = gdprFieldIsValid(event.target);
            if (hasErrors || !gdprIsValid) {
                return;
            }

            // Otherwise, let the form submit normally
            // You could also bolt in an Ajax form submit process here
            submitMailChimpForm(event.target);

        }, false);
    }

}(window, document));
