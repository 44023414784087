
(function ($) {
    "use strict";

    $('.variations_form').each(function () {
        var form = $(this);
        var table = form.find('table.variations');
        var select = table.find('select');
        var wrapper = $('<div class="subscription-variations" />');

        select.find('option').each(function () {
            var option = $(this);

            if (!option.attr('value')) {
                return;
            }

            var radio = $('<div class="subscription-variations__item">' +
                '<label>' +
                '<input type="radio" name="' + select.attr('name') + '" value="' + option.attr('value') + '"' + (option.prop('selected') ? ' checked>' : '>') +
                '<span>' + option.text() + '</span>' +
                '</label>' +
                '</div>');

            radio.appendTo(wrapper);

            radio.on('change', function () {
                select.val(option.attr('value')).trigger('change');
            });

        });

        wrapper.insertAfter(table);

        table.hide();
    });
}(jQuery));
