/**
 * Albo Certificati
 *
 * @see {@link plugins/jquery.magnific-popup.js}
 *
 * 01. Modal
 */

(function ($) {
    /**
     * Modal
     */
    (function () {
        $('.js-modal-albo').magnificPopup({
            type: 'inline',
            fixedContentPos: false,
            fixedBgPos: true,
            overflowY: 'auto',
            closeBtnInside: false,
            preloader: false,
            midClick: true,
            removalDelay: 300,
            mainClass: 'mfp-slide-bottom'
        });

        $(document).on('click', '.popup-modal-dismiss', function (e) {
            e.preventDefault();
            $.magnificPopup.close();
        });
    }());
}(jQuery));


/**
 * Gestisco la select delle regioni.
 */
(function (w, d) {
    var filterRegionField = d.querySelector('.js-filter-region');
    var filterProvinceField = d.querySelector('.js-filter-province');

    if (!filterRegionField || !filterProvinceField || !w.filterProvinces) {
        return;
    }

    function buildSelect(regionId) {
        regionId = parseInt(regionId, 10);

        var html = '<option value="">Qualsiasi</option>';
        w.filterProvinces.forEach(function (province) {
            if (regionId && regionId !== parseInt(province.region, 10)) {
                return;
            }

            html += '<option value="' + province.id + '">' + province.name + '</option>';
        });

        return html;
    }

    filterRegionField.addEventListener('change', function () {
        filterProvinceField.innerHTML = buildSelect(this.value);
    });
}(window, document));
