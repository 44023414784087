/**
 * App Calcolo Dieta
 *
 * @author Daniele De Nobili
 */

(function (d) {
    "use strict";

    var form = d.calculator;

    if (!form) {
        return;
    }

    var results = d.querySelector('.c-app-results');

    function init() {
        mount();
        bindEvents();
    }

    function mount() {
        // checked
        form.querySelectorAll('[checked]').forEach(setCheckedLabel);

        form.querySelectorAll('[data-gender-class]').forEach(function (element) {
            element.originalClassName = element.className;
            setGenderClass(element);
        });

        form.querySelectorAll('[data-gender-text]').forEach(setGenderText);
        form.querySelectorAll('img[data-gender-src]').forEach(setGenderImageSrc);
        form.querySelectorAll('input[data-gender-value]').forEach(setGenderInputValue);
    }

    function bindEvents() {
        form.addEventListener('submit', function (event) {
            event.preventDefault();

            if (validateForm()) {
                calculate();
            }
        });

        /*
         * Checked radio input
         */
        form.addEventListener('change', function (event) {
            if (event.target.type === 'radio') {
                setCheckedLabel(event.target);
            }
        });

        form.addEventListener('change', function (event) {
            if (event.target.name !== 'gender') {
                return;
            }

            form.querySelectorAll('[data-gender-class]').forEach(setGenderClass);
            form.querySelectorAll('[data-gender-text]').forEach(setGenderText);
            form.querySelectorAll('img[data-gender-src]').forEach(setGenderImageSrc);
            form.querySelectorAll('input[data-gender-value]').forEach(setGenderInputValue);
        });

        form.addEventListener('change', function (event) {
            validateField(event.target);

            Util.removeClass(results, '-show');
        });
    }

    function setGenderClass(element) {
        var dataClass = JSON.parse(element.dataset.genderClass);
        var className = element.originalClassName;

        if (dataClass[form.gender.value]) {
            className += ' ' + dataClass[form.gender.value];
        }

        element.className = className;
    }

    function setGenderText(element) {
        var dataText = JSON.parse(element.dataset.genderText);
        element.innerText = dataText[form.gender.value];
    }

    function setGenderImageSrc(imageElement) {
        var dataSrc = JSON.parse(imageElement.dataset.genderSrc);

        if (dataSrc[form.gender.value]) {
            imageElement.src = dataSrc[form.gender.value];
        }
    }

    function setGenderInputValue(inputElement) {
        var dataValue = JSON.parse(inputElement.dataset.genderValue);
        inputElement.value = dataValue[form.gender.value] || '';
    }

    function setCheckedLabel(inputElement) {
        inputElement.closest('.c-app-form__field').querySelectorAll('label').forEach(function (label) {
            Util.removeClass(label, '-checked');
        });

        Util.addClass(inputElement.closest('label'), '-checked');
    }

    function validateForm() {
        var valid = true;
        var fieldsName = [
            'height',
            'age',
            'weight',
            'gender',
            'lifestyle',
            'fm',
            'target'
        ];

        fieldsName.forEach(function (fieldName) {
            if (!validateField(form[fieldName])) {
                valid = false;
            }
        });

        return valid;
    }

    function validateField(field) {
        var value = field.value;

        if (field instanceof NodeList) {
            field = field[0];
        }

        if (field.type === 'range' || field.type === 'number') {
            value = parseInt(value, 10);
        }

        Util.toggleClass(field.closest('.c-app-form__field'), 'c-app-form__field--error', !value);

        return !!value;
    }

    function format(n) {
        return Math.round(n).toLocaleString(undefined);
    }

    function setValue(el, value) {
        var start = 0;
        var timer;
        var step = value / 50;

        timer = setInterval(function () {
            if (start >= value) {
                clearInterval(timer);
                el.innerText = format(value);

                return;
            }

            el.innerText = format(start);
            start += step;
        }, 30);
    }

    function calculate() {
        console.clear();

        var height = parseInt(form.height.value, 10);
        var age = parseInt(form.age.value, 10);
        var weight = parseInt(form.weight.value, 10);
        var gender = form.gender.value;
        var lifestyle = form.lifestyle.value;
        var person = form.person.value;
        var fm = parseInt(form.fm.value, 10); // massa grassa percentuale
        var lbm = 100 - fm; // massa magra percentuale
        var lbmWeight = weight * lbm / 100; // peso della massa magra
        var target = form.target.value;
        var daily;

        console.log('Altezza: ' + height + ' cm');
        console.log('Età: ' + age + ' anni');
        console.log('Peso: ' + weight + ' Kg');
        console.log('Sesso: ' + gender);
        console.log('Attività: ' + lifestyle);
        console.log('Struttura corporea: ' + person);
        console.log('Massa grassa: ' + fm + '%');
        console.log('Obiettivo: ' + target);

        console.log('Massa magra: ' + lbmWeight + ' Kg');

        /*
         * BMR (Metabolismo Basale)
         *
         * Se il soggetto seleziona una massa grassa uguale o inferiore al 15% per
         * gli uomini e al 20% per le donne, il sistema deve utilizzare la formula
         * di Cunningham, altrimenti Mifflin.
         */
        if (
            (gender === 'male' && fm <= 15)
            || (gender === 'female' && fm <= 20)
        ) {
            // Cunningham
            daily = 500 + (22 * lbmWeight);

            console.log('BMR: ' + daily + ' kcal (fino al 15% di massa grassa, Cunningham)');
        } else {
            // Mifflin St. Jeor
            if (gender === 'male') {
                daily = (10 * weight) + (6.25 * height) - (5 * age) + 5;
            } else {
                daily = (10 * weight) + (6.25 * height) - (5 * age) - 161;
            }

            console.log('BMR: ' + daily + ' kcal (sopra il 15% di massa grassa, Mifflin St. Jeor)');
        }


        /*
         * TDEE (Total Daily Energy Expenditure)
         *
         * Si calcola moltiplicando il BMR con un coeficiente in base all’attività fisica.
         */
        var lifestyleMap = {
            inactive: {
                male: 1.2,
                female: 1.1
            },
            lowActive: {
                male: 1.4,
                female: 1.3
            },
            active: {
                male: 1.6,
                female: 1.5
            },
            veryActive: {
                male: 1.8,
                female: 1.7
            },
            pro: {
                male: 2,
                female: 1.9
            }
        };

        daily *= lifestyleMap[lifestyle][gender];

        console.log('TDEE: ' + daily + ' kcal');


        /*
         * Obiettivo
         *
         * Implica un aumento (o una riduzione) delle calorie in base ad obiettivo e sesso.
         */
        var targetMap = {
            aggressiveWeightLoss: {
                male: -750,
                female: -500
            },
            moderateWeightLoss: {
                male: -500,
                female: -300
            },
            maintenance: {
                male: 0,
                female: 0
            },
            moderateMuscle: {
                male: 350,
                female: 250
            },
            aggressiveMuscle: {
                male: 550,
                female: 450
            }
        };

        if (targetMap[target] && targetMap[target][gender]) {
            daily += targetMap[target][gender];
        }

        console.log('calorie definitive: ' + daily + ' kcal');


        // Arrotondo per difetto
        daily = Math.floor(daily);

        console.log('calorie giornaliere arrotondate per difetto: ' + daily + ' kcal');


        /*
         * Calcolo macronutrienti
         *
         * - 1gr di grassi = 9kcal
         * - 1gr di carboidrati = 4kcal
         * - 1gr di proteine = 4kcal
         */
        var macroMap = {
            protein: {
                ectomorph: {
                    male: {
                        weightLoss: 2.5,
                        maintenance: 2,
                        muscle: 2.5
                    },
                    female: {
                        weightLoss: 2.2,
                        maintenance: 1.8,
                        muscle: 2.2
                    }
                },
                mesomorph: {
                    male: {
                        weightLoss: 2.5,
                        maintenance: 2,
                        muscle: 2.2
                    },
                    female: {
                        weightLoss: 2,
                        maintenance: 1.8,
                        muscle: 2
                    }
                },
                endomorph: {
                    male: {
                        weightLoss: 2.2,
                        maintenance: 2,
                        muscle: 2.2
                    },
                    female: {
                        weightLoss: 2,
                        maintenance: 1.8,
                        muscle: 2
                    }
                }
            },
            fat: {
                weightLoss: {
                    min: 0.5,
                    max: 1
                },
                maintenance: {
                    min: 0.6,
                    max: 1.2
                },
                muscle: {
                    min: 0.8,
                    max: 1.5
                }
            }
        };

        var targetTypeMap = {
            aggressiveWeightLoss: 'weightLoss',
            moderateWeightLoss: 'weightLoss',
            maintenance: 'maintenance',
            moderateMuscle: 'muscle',
            aggressiveMuscle: 'muscle'
        };

        var targetType = targetTypeMap[target];

        // @todo aggiungere la struttura corporea
        var proteinGr = macroMap.protein[person][gender][targetType] * weight;
        var proteinCal = proteinGr * 4;

        var fatGrMin = macroMap.fat[targetType].min * weight;
        var fatGrMax = macroMap.fat[targetType].max * weight;
        var fatCalMin = fatGrMin * 9;
        var fatCalMax = fatGrMax * 9;
        var carboCalMin = daily - fatCalMax - proteinCal;
        var carboCalMax = daily - fatCalMin - proteinCal;
        var carboGrMin = carboCalMin / 4;
        var carboGrMax = carboCalMax / 4;

        console.log(`Proteine: ${proteinGr} gr / ${proteinCal} kcal`);
        console.log(`Grassi: ${fatGrMin} / ${fatGrMax} gr – ${fatCalMin} / ${fatCalMax} kcal`);
        console.log(`Carboidrati: ${carboGrMin} / ${carboGrMax} gr – ${carboCalMin} / ${carboCalMax} kcal`);

        /*
         * Risultato finale
         */
        setValue(d.querySelector('.js-result-daily'), daily);
        setValue(d.querySelector('.js-result-weekly'), daily * 7);

        setValue(d.querySelector('.js-protein-gr'), proteinGr);
        setValue(d.querySelector('.js-protein-cal'), proteinCal);
        setValue(d.querySelector('.js-fat-gr-min'), fatGrMin);
        setValue(d.querySelector('.js-fat-gr-max'), fatGrMax);
        setValue(d.querySelector('.js-fat-cal-min'), fatCalMin);
        setValue(d.querySelector('.js-fat-cal-max'), fatCalMax);
        setValue(d.querySelector('.js-carbo-gr-min'), carboGrMin);
        setValue(d.querySelector('.js-carbo-gr-max'), carboGrMax);
        setValue(d.querySelector('.js-carbo-cal-min'), carboCalMin);
        setValue(d.querySelector('.js-carbo-cal-max'), carboCalMax);

        Util.addClass(results, '-show');

        window.location.hash = 'anchor-app-results';
    }

    init();
}(document));


/**
 * Tooltip
 *
 * @see https://codyhouse.co/ds/components/app/tooltip
 */
(function () {
    var Tooltip = function (element) {
        this.element = element;
        this.tooltip = false;
        this.tooltipIntervalId = false;
        this.tooltipContent = this.element.getAttribute('title');
        this.tooltipPosition = (this.element.getAttribute('data-tooltip-position')) ? this.element.getAttribute('data-tooltip-position') : 'top';
        this.tooltipId = 'js-tooltip-element'; // id of the tooltip element -> trigger will have the same aria-describedby attr
        this.tooltipDelay = 300; // show tooltip after a delay (in ms)
        this.tooltipDelta = 10; // distance beetwen tooltip and trigger element (in px)
        this.initTooltip();
    };

    Tooltip.prototype.initTooltip = function () {
        // reset trigger element
        this.element.removeAttribute('title');
        this.element.setAttribute('tabindex', '0');
        // add event listeners
        this.element.addEventListener('mouseenter', this);
        this.element.addEventListener('focus', this);
    };

    Tooltip.prototype.removeTooltipEvents = function () {
        // remove event listeners
        this.element.removeEventListener('mouseleave', this);
        this.element.removeEventListener('blur', this);
    };

    Tooltip.prototype.handleEvent = function (event) {
        // handle events
        switch (event.type) {
            case 'mouseenter':
            case 'focus':
                this.showTooltip(event);
                break;
            case 'mouseleave':
            case 'blur':
                this.hideTooltip();
                break;
        }
    };

    Tooltip.prototype.showTooltip = function (event) {
        var self = this;
        // tooltip has already been triggered
        if (this.tooltipIntervalId) return;
        // listen to close events
        this.element.addEventListener('mouseleave', this);
        this.element.addEventListener('blur', this);
        // show tooltip with a delay
        this.tooltipIntervalId = setTimeout(function () {
            self.createTooltip();
        }, self.tooltipDelay);
    };

    Tooltip.prototype.createTooltip = function (event) {
        this.tooltip = document.getElementById(this.tooltipId);

        if (!this.tooltip) { // tooltip element does not yet exist
            this.tooltip = document.createElement('div');
            document.body.appendChild(this.tooltip);
        }

        // reset tooltip content/position
        Util.setAttributes(this.tooltip, {
            'id': this.tooltipId,
            'class': 'c-tooltip c-tooltip--is-hidden js-tooltip',
            'role': 'tooltip'
        });
        this.tooltip.innerHTML = this.tooltipContent;
        this.element.setAttribute('aria-describedby', this.tooltipId);
        this.placeTooltip();
        Util.removeClass(this.tooltip, 'c-tooltip--is-hidden');
    };

    Tooltip.prototype.placeTooltip = function () {
        // set top and left position of the tooltip according to the data-tooltip-position attr of the trigger
        var dimention = [this.tooltip.offsetHeight, this.tooltip.offsetWidth],
            positionTrigger = this.element.getBoundingClientRect(),
            position = [],
            scrollY = window.scrollY || window.pageYOffset;

        position['top'] = [(positionTrigger.top - dimention[0] - this.tooltipDelta + scrollY), (positionTrigger.right / 2 + positionTrigger.left / 2 - dimention[1] / 2)];
        position['bottom'] = [(positionTrigger.bottom + this.tooltipDelta + scrollY), (positionTrigger.right / 2 + positionTrigger.left / 2 - dimention[1] / 2)];
        position['left'] = [(positionTrigger.top / 2 + positionTrigger.bottom / 2 - dimention[0] / 2 + scrollY), positionTrigger.left - dimention[1] - this.tooltipDelta];
        position['right'] = [(positionTrigger.top / 2 + positionTrigger.bottom / 2 - dimention[0] / 2 + scrollY), positionTrigger.right + this.tooltipDelta];

        var direction = this.tooltipPosition;
        if (direction === 'top' && position['top'][0] < scrollY) direction = 'bottom';
        else if (direction === 'bottom' && position['bottom'][0] + this.tooltipDelta + dimention[0] > scrollY + window.innerHeight) direction = 'top';
        else if (direction === 'left' && position['left'][1] < 0) direction = 'right';
        else if (direction === 'right' && position['right'][1] + dimention[1] > window.innerWidth) direction = 'left';

        if (direction === 'top' || direction === 'bottom') {
            if (position[direction][1] < 0) position[direction][1] = 0;
            if (position[direction][1] + dimention[1] > window.innerWidth) position[direction][1] = window.innerWidth - dimention[1];
        }
        this.tooltip.style.top = position[direction][0] + 'px';
        this.tooltip.style.left = position[direction][1] + 'px';
        Util.addClass(this.tooltip, 'c-tooltip--' + direction);
    };

    Tooltip.prototype.hideTooltip = function () {
        var self = this;
        clearInterval(this.tooltipIntervalId);
        this.tooltipIntervalId = false;
        if (!this.tooltip) return;
        // hide tooltip
        this.removeTooltip();
        // remove events
        this.removeTooltipEvents();
    };

    Tooltip.prototype.removeTooltip = function (event) {
        Util.addClass(this.tooltip, 'c-tooltip--is-hidden');
        this.tooltip = false;
        this.element.removeAttribute('aria-describedby');
    };

    //initialize the Tooltip objects
    var tooltips = document.getElementsByClassName('js-tooltip-trigger');
    if (tooltips.length > 0) {
        for (var i = 0; i < tooltips.length; i++) {
            (function (i) {
                new Tooltip(tooltips[i]);
            })(i);
        }
    }
}());