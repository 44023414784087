/**
 * Global
 *
 * 01. SVG
 * 02. Social Share
 * 03. Fluid Video
 * 04. Magnific Popup
 * 05. Standard Carousel
 * 06. Video Replacement
 *
 * @global jQuery
 * @author Matteo Casonato
 */


(function ($) {
	'use strict';

    /**
     * Magnific Popup
     *
     * @requires plugins/jquery.magnific-popup.js
     */
    (function () {
        $('.woocommerce-product-gallery__image').magnificPopup({
            delegate: 'a',
            type: 'image',
            closeOnContentClick: false,
            closeBtnInside: false,
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            image: {
                verticalFit: true
            },
            zoom: {
                enabled: true,
                duration: 300, // don't foget to change the duration also in CSS
                opener: function(element) {
                    return element.find('img');
                }
            }
        });

        $('.js-videopopup').magnificPopup({
            //disableOn: 700,
            type: 'iframe',
            removalDelay: 500, //delay removal by X to allow out-animation
            mainClass: 'mfp-fade',
            preloader: false,
            fixedContentPos: true,
            iframe: {
                patterns: {
                    youtube: {
                        index: 'youtube.com/',
                        id: 'v=',
                        src: '//www.youtube.com/embed/%id%?autoplay=1&autohide=1&border=0&wmode=opaque&controls=1&showinfo=0&rel=0&hd=1'
                    },
                    vimeo: {
                        index: 'vimeo.com/',
                        id: '/',
                        src: '//player.vimeo.com/video/%id%?autoplay=1'
                    }
                }
            }
        });
    }());


    /**
     * Standard Carousel
     *
     * Utilizzato in home sul network e nei post correlati
     *
     * @requires plugins/jquery.swiper.js
     */
    (function () {
        var swiperCarousel = new Swiper('.js-carousel', {
            slidesPerView: 'auto',
            spaceBetween: '3.597%',
            freeMode: true,
            watchSlidesVisibility: true,
            pagination: {
                el: '.js-carouselPagination',
                clickable: true
            },
            preloadImages: false
        });
    }());


    /**
     * Video Replacement
     *
     * @global jQuery
     * @author Daniele De Nobili
     */
    (function () {
        var videoReplaceImage = $('.js-videoimage'),
            videoReplaceMedia = $('.js-videoiframe');

        videoReplaceImage.on('click', function () {
            var url = '';
            videoReplaceImage.hide();

            if (videoReplaceMedia.data('source') === 'vimeo') {
                url = 'https://player.vimeo.com/video/' + videoReplaceMedia.data('video-id') + '?';
                if (videoReplaceMedia.data('video-h')) {
                    url += 'h=' + videoReplaceMedia.data('video-h') + '&';
                }
                url += 'autoplay=1&byline=0&badge=0&title=0&loop=1';
            } else {
                url = 'https://www.youtube.com/embed/' + videoReplaceMedia.data('video-id') + '?showinfo=0&rel=0&autoplay=1&autohide=1&color=white';
            }

            var videoReplaceIframe = $('<iframe width="1200" height="675" src="' + url + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');

            videoReplaceIframe.attr('data-title', videoReplaceMedia.data('title'));

            videoReplaceMedia.html(videoReplaceIframe).show();
        });
    }());


    /**
     * Search Clean Url
     */
    (function () {
        document.querySelectorAll('.js-search-form').forEach(function (form) {
            form.addEventListener('submit', function (event) {
                event.preventDefault();

                var url = form.action + 'search/' + encodeURI(form.s.value).replace(/%20/g, '+') + '/';
                var qs = '';

                form.querySelectorAll('input, select, textarea').forEach(function (field) {
                    if (field.name === 's') {
                        return;
                    }

                    qs += field.name + '=' + encodeURI(form[field.name].value) + '&';
                });

                if (qs) {
                    qs = qs.substr(0, qs.length - 1);
                    url += '?' + qs;
                }

                window.location = url;
            });
        });
    }());

}(jQuery));