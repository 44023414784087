/**
 * Supports
 *
 * 01. SVG
 * 01. Facebook SDK
 */
(function (w, d) {
    'use strict';

    /**
     * SVG
     *
     * @see {@link libraries/svg4everybody.js}
     */
    svg4everybody();


    /**
     * Facebook SDK
     *
     * @see https://gist.github.com/jimi008/5208cf9956beaf0416f04d84ced41dda
     */
    (function () {
        var comments = d.querySelector('.js-comments');

        if (!comments) {
            return;
        }

        if (typeof onCookiePolicyAccepted === 'function') {
            w.onCookiePolicyAccepted(function () {
                if (typeof fbLang !== 'undefined') {

                    function loadAPI() {
                        var js = d.createElement('script');
                        js.src = '//connect.facebook.net/' + fbLang + '/sdk.js#xfbml=1&appId=' + fbAppId + '&version=v3.2';
                        d.body.appendChild(js);
                    }

                    w.onscroll = function () {
                        if (comments.getBoundingClientRect().top < w.innerHeight) {
                            loadAPI();
                            w.onscroll = null;
                        }
                    }

                }
            });
        }
    }());

}(window, document));