/**
 * Sidebar
 *
 * 01. Navigation
 * 02. Most Viewed
 *
 * @global jQuery
 * @author Matteo Casonato
 */
(function ($) {
    'use strict';

    /**
     * Navigation
     *
     * @requires plugins/jquery.accordion.js
     */
    (function () {
        var submenu = $('.js-submenu');

        if (!submenu.length) {
            return;
        }

        $('<svg class="submenu__accordion"><use xlink:href="#icons--chevron-right"></use></svg>').appendTo(submenu.find('.submenu__dropdown').children('.submenu__link'));

        submenu.find('.submenu__item--parent').addClass('submenu__item--open');
        submenu.find('.submenu__item--active').addClass('submenu__item--open');

        submenu.accordion({
            panelSelector	: '.submenu__dropdown',
            titleSelector	: '> .submenu__link .submenu__accordion',
            contentSelector	: '.submenu__sub',
            activeClass		: 'submenu__item--open'
        });
    }());


    /**
     * Most Viewed
     *
     * @link https://stackoverflow.com/questions/21538056/destroy-idangerous-swiper-if-window-is-resized-to-a-bigger-resolution-or-call-it/29709629#29709629
     *
     * @requires plugins/jquery.swiper.js
     */
    (function () {
        let mostViewed = document.querySelector('.js-mostviewed');
        let swiper;

        if (!mostViewed) {
            return;
        }

        function onChange(event) {
            if (event.matches && !swiper) {
                // console.log('desktop');
                swiper = new Swiper(mostViewed, {
                    slidesPerView: 1,
                    slidesPerColumn: 4,
                    spaceBetween: 0,
                    watchSlidesVisibility: true,
                    pagination: {
                        el: '.js-mostviewedPagination',
                        clickable: true
                    },
                    preloadImages: false
                });
            } else if (swiper) {
                // console.log('desktop to mobile');
                swiper.destroy();
                swiper = undefined;

                document.querySelectorAll('.js-mostviewed, .swiper-wrapper, .swiper-slide').forEach(function (el) {
                    el.removeAttribute('style');
                });
            }
        }

        var mq = window.matchMedia("(min-width: 1200px)");
        mq.addEventListener('change', onChange);
        onChange(mq);

    }());

}(jQuery));
